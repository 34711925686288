//定义路由
const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    component: () => import("@/views/home/index.vue"),
    meta: {
      title: "云图直播_企业级直播平台官方网站",
    },
  },
  {
    path: "/download/app",
    component: () => import("@/views/download/app/index.vue"),
    meta: {
      title: "直播App_云图直播_企业直播营销服务平台",
    },
  },
  {
    path: "/solution/case",
    component: () => import("@/views/solution/case/index.vue"),
    meta: {
      title: "客户案例_云图直播_企业直播营销服务平台",
    },
  },
  {
    path: "/solution/caseDetile/:id",
    component: () => import("@/views/solution/caseDetile/index.vue"),
    name: "caseDetile",
    meta: {
      title: "直播案例_云图直播_企业直播营销服务平台",
    },
  },
  {
    path: "/help/helpCenter",
    component: () => import("@/views/help/helpCenter/index.vue"),
    name: "帮助中心",
    meta: {
      title: "帮助中心_云图直播_企业直播营销服务平台",
    },
  },
  {
    path: "/help/helpDocument",
    component: () => import("@/views/help/helpDocument/index.vue"),
    meta: {
      title: "帮助文档_云图直播_企业直播营销服务平台",
    },
  },
  {
    path: "/company",
    component: () => import("@/views/company/index.vue"),
    name: "公司简介",
    meta: {
      title: "公司简介_云图直播_企业直播营销服务平台",
    },
  },
  {
    path: "/cooperation",
    component: () => import("@/views/cooperation/index.vue"),
    name: "渠道合作",
    meta: {
      title: "渠道合作_云图直播_企业直播营销服务平台",
    },
  },
  {
    path: "/news/:id?",
    component: () => import("@/views/news/index.vue"),
    name: "媒体报道",
    meta: {
      title: "媒体报道_云图直播_企业直播营销服务平台",
    },
  },
  // {
  //   path: "/test",
  //   component: () => import("@/views/test.vue"),
  //   meta: {
  //     title: "测试页面_云图直播_企业直播营销服务平台",
  //   },
  // },
  {
    path: "/404",
    component: () => import("@/views/404.vue"),
    meta: {
      title: "云图直播_企业级直播平台",
    },
  },
  {
    path: "*",
    redirect: "/404",
  },
];

export default routes;
