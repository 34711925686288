// 将iconsvg注册成全局组件
import Vue from 'vue'
import IconSvg from '@/components/IconSvg/index.vue'
Vue.component(IconSvg.name, IconSvg)

// 导入所有的svg图标
// 加载svg目录中的所有以.svg结尾的文件 (true含当前目录的子孙目录，false仅在当前目录，不会找子孙目录)
const files = require.context('./svg', false, /\.svg$/)

// console.log('files', files.keys())

// 将svg图标全部自动加载
files.keys().forEach(files)