import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index.js";
import "./assets/css/rest.css";
// 导入svg图标
import "@/icons";
// 导入element-ui
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// 禁用生产环境提示
import moment from "moment";
Vue.prototype.$moment = moment;
Vue.config.productionTip = false;
Vue.use(ElementUI);

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
