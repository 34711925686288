import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";

Vue.use(VueRouter);

// 先保存原始的 VueRoute push方法的引用
const originPush = VueRouter.prototype.push;
const originReplace = VueRouter.prototype.replace;

// 后重写
VueRouter.prototype.push = function (location, okCallback, failCallback) {
  console.log("push重写了");
  if (okCallback && failCallback) {
    return originPush.call(this, location, okCallback, failCallback);
  } else {
    return originPush.call(this, location).catch(() => {});
  }
};

VueRouter.prototype.replace = function (location, okCallback, failCallback) {
  console.log("replace重写了");
  if (okCallback && failCallback) {
    return originReplace.call(this, location, okCallback, failCallback);
  } else {
    return originReplace.call(this, location).catch(() => {});
  }
};

const router = new VueRouter({
  mode: "hash",
  routes,
});

// 路由跳转前
router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

// 路由跳转后
router.afterEach((to, from) => {
  window.scrollTo(0, 0); // 每次路由改变滚动条都回到顶部
});

export default router;
