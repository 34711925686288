<template>
    <svg aria-hidden="true" :width="width" :height="height" :fill="color">
      <use :xlink:href="iconName"></use>
    </svg>
  </template>
  
  <script>
    export default {
      name: 'IconSvg',
      props: {
        color: {
          type: String,
        },
        width: {
          type: String,
          default:'20px'
        },
        height: {
          type: String,
          default:'20px'
        },
        iconClass: {
          type: String,
          required: true
        }
      },
      computed: {
        iconName() {
          return `#icon-${this.iconClass}`
        }
      }
    }
  </script>